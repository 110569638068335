<template>
  <v-container class="produttori-locali">
    <Breadcrumb :items="breadcrumb" />
    <h1 v-html="title"></h1>
    <p v-html="content"></p>
    <ProvincesFilter path="produttori-locali" class="mb-4"></ProvincesFilter>
    <v-row v-if="posts">
      <v-col
        cols="12"
        sm="6"
        md="3"
        lg="3"
        v-for="post in posts"
        :key="post.id"
      >
        <v-card
          class="d-flex pb-2 flex-column align-center justify-center text-center h-100"
          outlined
          :to="{
            name: 'ProduttoriDetail',
            params: { postName: post.slug }
          }"
        >
          <v-img contain :src="post.featured_image_url" width="150"> </v-img>
          <h2 class="primary--text text-h3">{{ post.title.rendered }}</h2>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import clickHandler from "~/mixins/clickHandler";
import CMService from "~/service/cmService";
import ProvincesFilter from "@/components/wordpress/ProvincesFilter.vue";
import page from "~/mixins/page";
import Breadcrumb from "@/components/navigation/Breadcrumb.vue";

export default {
  name: "ProduttoriList",
  data() {
    return {
      posts: null,
      noPostToShow: false
    };
  },
  mixins: [clickHandler, page],
  components: { ProvincesFilter, Breadcrumb },
  methods: {
    async fetchPosts() {
      let params = [];

      const res = await CMService.getCustomPostByFilters(
        "produttore",
        params,
        100
      );
      if (res && res.data && res.data.length) {
        this.posts = res.data;
      } else {
        this.posts = null;
      }
    }
  },
  mounted() {
    this.page = this.$route.params.page;
    this.fetchPosts();
  }
};
</script>
