<template>
  <div class="provinces-filter pa-5 primary rounded-md" v-if="filters">
    <v-row class="justify-md-space-between justify-lg-start align-center">
      <v-col
        cols="12"
        md="3"
        v-for="(filter, index) in filters"
        :key="filter.id"
      >
        <v-select
          :items="filter.children"
          :label="filter.text"
          v-model="models[index]"
          class="white default--text rounded-0 mb-3 mb-md-0"
          single-line
          outlined
          hide-details
        >
        </v-select>
      </v-col>

      <v-col cols="12" md="auto">
        <v-btn @click="search" class="rounded-pill elevation-0 pa-6 w-100"
          >{{ $t("cta.search") }}
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>
<style lang="scss" scoped></style>

<script>
import CMService from "~/service/cmService";

export default {
  name: "ProvincesFilter",
  data() {
    return {
      filters: null,
      models: []
    };
  },
  props: {
    path: { type: String, required: true }
  },
  methods: {
    async fetchCategories() {
      const categories = await CMService.getCustomPostByFilters(
        "provincia",
        null,
        100
      );
      const categoryTree = categories.data.reduce((acc, item) => {
        if (item.parent === 0) {
          const children = categories.data
            .filter(child => child.parent === item.id)
            .map(child => ({
              text: child.name,
              value: child.id,
              id: child.id
            }));
          acc.push({
            text: item.name,
            value: item.id,
            children
          });
        }
        return acc;
      }, []);

      this.filters = categoryTree;
    },
    search() {
      const categoryIds = this.models
        .filter(category => category != null)
        .join(",");

      if (categoryIds.length) {
        this.$router.push({
          path: "/" + this.path,
          query: { filter: categoryIds }
        });
      }
    }
  },
  mounted() {
    this.fetchCategories();
  }
};
</script>
